import * as CTypes from "../types";
import * as _log from "../_log";
import * as _utils from "../_utils";
import { DataError, RoomError, SDKActivationError } from "../_errors";

/**
 * Add message to given room
 * (logic for _addMessageToRoom private function)
 */
const addMessageToRoom = (props: {
  message: CTypes.Message;
  rooms: CTypes.RoomList;
  currentUser: CTypes.SDK["currentUser"];
  roomID?: string;
}): CTypes.RoomList => {
  const { message, rooms, currentUser } = { ...props };
  let { roomID } = props;

  // Validate state value
  if (!currentUser) {
    throw new DataError("Can't add message to room, no currentUser data");
  }

  if (!roomID) {
    // if they didn't pass in a room ID, infer it from the message
    if (message.to === currentUser.user_id) {
      roomID = message.from;
    } else if (message.from === currentUser.user_id) {
      roomID = message.to;
    } else {
      _log.warn("Something went wrong with message.");
      _log.warn(message);
      return rooms;
    }
  }

  // Does this message ID already exist in this room?
  if (
    rooms[roomID]?.messages?.find((m: CTypes.Message) => m._id === message._id)
  ) {
    let messages = rooms[roomID].messages;
    const m = messages.find((m: CTypes.Message) => m._id === message._id);

    // is it pending? set it to delivered
    if (m?.sentStatus === "sent") {
      messages = messages.map((el: CTypes.Message) =>
        el._id === message._id ? { ...el, sentStatus: "delivered" } : el
      );

      return {
        ...rooms,
        [roomID]: { ...rooms[roomID], messages },
      };
    }
    return rooms;
  }

  // Add message to room
  const tmpRoom = { ...(rooms[roomID] || { room_id: roomID, type: "MUC" }) }; // if room doesn't already exist, set it to empty object
  const messages = [...(tmpRoom.messages || [])]; // if room didn't already exist, default to [] for messages
  messages.push(message);
  tmpRoom.messages = [..._utils.sortMessages(messages)];

  // Return updated rooms state
  return {
    ...rooms,
    [roomID]: tmpRoom,
  };
};

export default addMessageToRoom;
